body {
  margin: 0;
}

#root {
  min-height: 100vh;
  
  /* grid container settings */
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto ;
  grid-template-areas: 
    'header'
    'main'
    'footer';
}

#root > header {
  grid-area: header;
}

#root > main {
  grid-area: main;
  overflow: auto; 
  padding: 15px 5px 10px 5px;
}

#root > main > sections {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: auto auto;
  /*background-color: #2196F3;*/
  padding: 10px;
}

#root > main > sections > items {
  background-color: rgba(255, 255, 255, 0.8);
 /* border: 1px solid rgba(0, 0, 0, 0.8);*/
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

#root > footer {
  grid-area: footer;
}